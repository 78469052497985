<template>
  <base-layout-two page-title="Edit Reservation" :page-default-back-link="`/reservations/${$route.params.id}`">
    <section class="padding-8">
      <v-form @submit="onSubmit">
        <ion-grid class="ion-no-padding grid-padding">
          <ion-row>
            <ion-col size="12" size-lg="6">
              <base-card title="Status">
                <base-input label-text="Status *">
                  <v-field name="status" v-slot="{ field }" v-model="reservation.status">
                    <select v-bind="field" class="regular-select">
                      <option value="hold">Hold</option>
                      <option value="confirmed">Confirmed</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  </v-field>
                </base-input>

                <base-input label-text="Cancellation Notes" v-if="reservation.status === 'cancelled'">
                  <v-field name="cancellation_notes" v-slot="{ field }" v-model="reservation.cancellation_notes">
                    <ion-input name="cancellation_notes" v-bind="field" placeholder="" type="text" autocapitalize
                      autocorrect></ion-input>
                  </v-field>
                </base-input>
              </base-card>
            </ion-col>

            <ion-col size="12" size-lg="6">
              <base-card title="Support Person Meals">
                <base-input label-text="Support Person Meals">
                  <v-field name="support_person_meals" v-slot="{ field }" v-model="reservation.support_person_meals">
                    <select v-bind="field" class="regular-select">
                      <option :value="0">No</option>
                      <option :value="1">Yes</option>
                    </select>
                  </v-field>
                </base-input>
              </base-card>
            </ion-col>

            <ion-col size="12">
              <base-card title="Search Availability">
                <ion-grid>
                  <ion-row>
                    <!-- Property -->
                    <ion-col>
                      <base-input label-text="Property *">
                        <v-field name="property" v-slot="{ field }" v-model="reservation.property_id" :rules="required">
                          <select v-bind="field" @change="selectedPropertyUpdateRoomTypes()" class="regular-select">
                            <option v-for="property in properties" :key="property.id" :value="property.id">
                              {{ property.name }}
                            </option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="property" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>

                  <ion-row>
                    <!-- Arrival Date -->
                    <ion-col size="12" size-lg="4">
                      <base-input label-text="Arrival Date *">
                        <v-field name="arrival_date" v-slot="{ field }" v-model="reservation.arrival_date"
                          :rules="required">
                          <ion-input name="arrival_date" v-bind="field" placeholder="" type="date"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="arrival_date" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Number nights -->
                    <ion-col size="12" size-lg="4">
                      <base-input label-text="Number Nights *">
                        <v-field name="search_duration" v-slot="{ field }" v-model="reservation.duration"
                          :rules="required">
                          <select v-bind="field" class="regular-select" :disabled="!this.reservation.arrival_date">
                            <!-- <select v-bind="field" class="regular-select"> -->
                            <option :value="1">1 night</option>
                            <option :value="2">2 nights</option>
                            <option :value="3">3 nights</option>
                            <option :value="4">4 nights</option>
                            <option :value="5">5 nights</option>
                            <option :value="6">6 nights</option>
                            <option :value="7">7 nights</option>
                            <option :value="8">8 nights</option>
                            <option :value="9">9 nights</option>
                            <option :value="10">10 nights</option>
                            <option :value="11">11 nights</option>
                            <option :value="12">12 nights</option>
                            <option :value="13">13 nights</option>
                            <option :value="14">14 nights</option>
                            <option :value="15">15 nights</option>
                            <option :value="16">16 nights</option>
                            <option :value="17">17 nights</option>
                            <option :value="18">18 nights</option>
                            <option :value="19">19 nights</option>
                            <option :value="20">20 nights</option>
                            <option :value="21">21 nights</option>
                            <option :value="22">22 nights</option>
                            <option :value="23">23 nights</option>
                            <option :value="24">24 nights</option>
                            <option :value="25">25 nights</option>
                            <option :value="26">26 nights</option>
                            <option :value="27">27 nights</option>
                            <option :value="28">28 nights</option>
                            <option :value="29">29 nights</option>
                            <option :value="30">30 nights</option>
                            <option :value="31">31 nights</option>
                            <option :value="32">32 nights</option>
                            <option :value="33">33 nights</option>
                            <option :value="34">34 nights</option>
                            <option :value="35">35 nights</option>
                            <option :value="36">36 nights</option>
                            <option :value="37">37 nights</option>
                            <option :value="38">38 nights</option>
                            <option :value="39">39 nights</option>
                            <option :value="40">40 nights</option>
                            <option :value="41">41 nights</option>
                            <option :value="42">42 nights</option>
                            <option :value="43">43 nights</option>
                            <option :value="44">44 nights</option>
                            <option :value="45">45 nights</option>
                            <option :value="46">46 nights</option>
                            <option :value="47">47 nights</option>
                            <option :value="48">48 nights</option>
                            <option :value="49">49 nights</option>
                            <option :value="50">50 nights</option>
                            <option :value="51">51 nights</option>
                            <option :value="52">52 nights</option>
                            <option :value="53">53 nights</option>
                            <option :value="54">54 nights</option>
                            <option :value="55">55 nights</option>
                            <option :value="56">56 nights</option>
                            <option :value="57">57 nights</option>
                            <option :value="58">58 nights</option>
                            <option :value="59">59 nights</option>
                            <option :value="60">60 nights</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="search_duration" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <ion-col size="12" size-lg="4">
                      <base-input label-text="Departure Date *">
                        <v-field name="departure_date" v-slot="{ field }" v-model="reservation.departure_date"
                          :rules="required">
                          <ion-input name="departure_date" v-bind="field" placeholder="" type="date"
                            :min="minDepartureDate" disabled></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="departure_date" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>
                </ion-grid>

                <!-- <ion-button @click="searchAvailability()">Search</ion-button> -->
                <ion-button @click="fetchAllRooms">Search*</ion-button>
                <p><i><strong>*Temporarily returns ALL rooms, whether they are occupied or not</strong></i></p>

                <section v-if="showAvailableRooms">
                  <h3>Available Rooms</h3>
                  <p v-if="available_rooms.length === 0"></p>
                  <table width="100%" v-if="available_rooms.length > 0">
                    <thead>
                      <tr>
                        <td>
                          <h6>Room Type</h6>
                        </td>
                        <td>
                          <h6>Room Number</h6>
                        </td>
                        <td>
                          <h6>Retail Price</h6>
                        </td>
                      </tr>
                    </thead>
                    <tr v-for="(room, index) in available_rooms" :key="room.id" :bgcolor="index % 2 ? '#ddd' : '#fff'">
                      <td>
                        <p>{{ room.room_type.name }}</p>
                      </td>
                      <td>
                        <p>{{ room.number }}</p>
                      </td>
                      <td>
                        <p>
                          ${{
    room.room_type.price_retail.toLocaleString("en", {
      minimumFractionDigits: 0,
    })
  }}
                        </p>
                      </td>
                    </tr>
                  </table>
                </section>
              </base-card>
            </ion-col>

            <!-- Original Reservation -->
            <ion-col size="12" size-lg="4">
              <base-card title="Original Reservation">
                <ion-grid class="ion-no-padding">
                  <ion-row class="margin-bottom-16">
                    <ion-col size="6">
                      <base-field-display label-text="Room Type" lines="none">
                        <p>{{ originalReservation.room_type.name }}</p>
                      </base-field-display>
                    </ion-col>

                    <ion-col size="6">
                      <base-field-display label-text="Room" lines="none">
                        <p>{{ originalReservation.room.number }}</p>
                      </base-field-display>
                    </ion-col>
                  </ion-row>

                  <ion-row class="margin-bottom-16">
                    <ion-col size="4">
                      <base-field-display label-text="Arrival" lines="none">
                        <p>
                          {{
    formatDate(
      originalReservation.arrival_date,
      "MM/dd/yyyy"
    )
  }}
                        </p>
                      </base-field-display>
                    </ion-col>
                    <ion-col size="4">
                      <base-field-display label-text="Nights" lines="none">
                        <p>{{ originalReservation.duration }} nights</p>
                      </base-field-display>
                    </ion-col>
                    <ion-col size="4">
                      <base-field-display label-text="Departure" lines="none">
                        <p>
                          {{
    formatDate(
      originalReservation.departure_date,
      "MM/dd/yyyy"
    )
  }}
                        </p>
                      </base-field-display>
                    </ion-col>
                  </ion-row>

                  <ion-row class="margin-bottom-16">
                    <ion-col size="3">
                      <base-field-display label-text="Daily Rate" lines="none">
                        <p>
                          ${{ formatCurrency(originalReservation.price_daily) }}
                        </p>
                      </base-field-display>
                    </ion-col>
                    <ion-col size="3">
                      <base-field-display label-text="Retail" lines="none">
                        <p>
                          ${{ formatCurrency(originalReservation.price_retail) }}
                        </p>
                      </base-field-display>
                    </ion-col>
                    <ion-col size="3">
                      <base-field-display label-text="Discount" lines="none">
                        <p>
                          ${{
    formatCurrency(originalReservation.price_discount)
  }}
                        </p>
                      </base-field-display>
                    </ion-col>
                    <ion-col size="3">
                      <base-field-display label-text="Guest's Price" lines="none">
                        <p>
                          ${{ formatCurrency(originalReservation.price_final) }}
                        </p>
                      </base-field-display>
                    </ion-col>
                  </ion-row>

                  <ion-row class="margin-bottom-16">
                    <ion-col size="4">
                      <base-field-display label-text="Est. Taxes & Fees" lines="none">
                        <p>
                          ${{
    formatCurrency(
      originalReservation.price_estimated_taxes_fees
    )
  }}
                        </p>
                      </base-field-display>
                    </ion-col>
                    <ion-col size="4">
                      <base-field-display label-text="Est. Total" lines="none">
                        <p>
                          ${{
      formatCurrency(
        originalReservation.price_estimated_total
      )
    }}
                        </p>
                      </base-field-display>
                    </ion-col>
                    <ion-col size="4">
                      <base-field-display label-text="Deposit" lines="none">
                        <p>
                          ${{
      formatCurrency(originalReservation.price_deposit)
    }}
                          {{
      originalReservationDepositPaid
        ? "(Paid)"
        : "(Unpaid)"
    }}
                        </p>
                      </base-field-display>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </base-card>
            </ion-col>

            <!-- Updated Reservation -->
            <ion-col size="12" size-lg="8">
              <base-card title="Updated Reservation Details">
                <p>
                  <i>To make changes, first check availability above.</i>
                </p>
                <ion-grid>
                  <ion-row>
                    <!-- Property -->
                    <!-- using v-if length > 0 as a temporary bug fix for ion-select not showing up from http requests -->
                    <ion-col size="12">
                      <base-input label-text="Property *" class="placeholder-color">
                        <v-field name="property" v-slot="{ field }" v-model="reservation.property_id" :rules="required">
                          <select v-bind="field" @change="selectedPropertyUpdateRoomTypes()" class="regular-select"
                            disabled>
                            <option v-for="property in properties" :key="property.id" :value="property.id">
                              {{ property.name }}
                            </option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="property" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>

                  <ion-row>
                    <!-- Room Type -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Room Type" :class="showAvailableRooms ? '' : 'placeholder-color'">
                        <v-field name="room_type" v-slot="{ field }" v-model="reservation.room_type_id">
                          <select v-bind="field" @change="selectedRoomTypeUpdateRooms()" class="regular-select"
                            :disabled="!showAvailableRooms">
                            <option v-for="room_type in room_types" :key="room_type.id" :value="room_type.id">
                              {{ room_type.name }}
                            </option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="room_type" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Room -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Room" :class="showAvailableRooms ? '' : 'placeholder-color'">
                        <v-field name="room" v-slot="{ field }" v-model="reservation.room_id">
                          <select v-bind="field" class="regular-select" :disabled="!showAvailableRooms">
                            <option v-for="room in rooms" :key="room.id" :value="room.id">
                              {{ room.number }}
                            </option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="room" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>

                  <ion-row>
                    <!-- Arrival Date -->
                    <ion-col size="12" size-lg="4">
                      <base-input label-text="Arrival Date *">
                        <v-field name="arrival_date" v-slot="{ field }" v-model="reservation.arrival_date"
                          :rules="required">
                          <ion-input name="arrival_date" v-bind="field" placeholder="" type="date"
                            :disabled="true"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="arrival_date" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Number nights -->
                    <ion-col size="12" size-lg="4">
                      <base-input label-text="Number Nights *" class="placeholder-color">
                        <v-field name="duration" v-slot="{ field }" v-model="reservation.duration" :rules="required">
                          <select v-bind="field" @change="updateDepartureDate()" class="regular-select"
                            :disabled="true">
                            <option :value="1">1 night</option>
                            <option :value="2">2 nights</option>
                            <option :value="3">3 nights</option>
                            <option :value="4">4 nights</option>
                            <option :value="5">5 nights</option>
                            <option :value="6">6 nights</option>
                            <option :value="7">7 nights</option>
                            <option :value="8">8 nights</option>
                            <option :value="9">9 nights</option>
                            <option :value="10">10 nights</option>
                            <option :value="11">11 nights</option>
                            <option :value="12">12 nights</option>
                            <option :value="13">13 nights</option>
                            <option :value="14">14 nights</option>
                            <option :value="15">15 nights</option>
                            <option :value="16">16 nights</option>
                            <option :value="17">17 nights</option>
                            <option :value="18">18 nights</option>
                            <option :value="19">19 nights</option>
                            <option :value="20">20 nights</option>
                            <option :value="21">21 nights</option>
                            <option :value="22">22 nights</option>
                            <option :value="23">23 nights</option>
                            <option :value="24">24 nights</option>
                            <option :value="25">25 nights</option>
                            <option :value="26">26 nights</option>
                            <option :value="27">27 nights</option>
                            <option :value="28">28 nights</option>
                            <option :value="29">29 nights</option>
                            <option :value="30">30 nights</option>
                            <option :value="31">31 nights</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="duration" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <ion-col size="12" size-lg="4">
                      <base-input label-text="Departure Date *">
                        <v-field name="departure_date" v-slot="{ field }" v-model="reservation.departure_date"
                          :rules="required">
                          <ion-input name="departure_date" v-bind="field" placeholder="" type="date"
                            disabled></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="departure_date" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>

                  <ion-row>
                    <!-- Daily Rate -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Room Daily Rate *">
                        <v-field name="price_daily" v-slot="{ field }" v-model="reservation.price_daily"
                          :rules="required">
                          <ion-input name="price_daily" v-bind="field" placeholder="0.00" type="number"
                            disabled></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="price_daily" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Retail Price -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Retail Price *">
                        <v-field name="price_retail" v-slot="{ field }" v-model="reservation.price_retail"
                          :rules="required">
                          <ion-input name="price_retail" v-bind="field" placeholder="0.00" type="number"
                            disabled></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="price_retail" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Discount % -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Discount % (0-100)">
                        <v-field name="price_discount" v-slot="{ field }" v-model="reservation.price_discount"
                          :rules="required">
                          <ion-input name="price_discount" v-bind="field" placeholder="0" type="number" min="0"
                            max="100" :disabled="!showAvailableRooms"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="price_discount" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Final Price -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Guest's Price (before taxes & fees) *">
                        <v-field name="price_final" v-slot="{ field }" v-model="reservation.price_final"
                          :rules="required">
                          <ion-input name="price_final" v-bind="field" placeholder="0.00" type="number"
                            :disabled="!showAvailableRooms"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="price_final" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>

                  <ion-row>
                    <!-- Taxes & Fees -->
                    <ion-col size="12" size-lg="4">
                      <base-input label-text="Estimated Taxes & Fees">
                        <v-field name="price_estimated_taxes_fees" v-slot="{ field }"
                          v-model="reservation.price_estimated_taxes_fees" :rules="required">
                          <ion-input name="price_estimated_taxes_fees" v-bind="field" placeholder="0.00" type="number"
                            disabled></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="price_estimated_taxes_feesx" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Estimated Total -->
                    <ion-col size="12" size-lg="4">
                      <base-input label-text="Estimated Total">
                        <v-field name="price_estimated_total" v-slot="{ field }"
                          v-model="reservation.price_estimated_total" :rules="required">
                          <ion-input name="price_estimated_total" v-bind="field" placeholder="0.00" type="number"
                            disabled></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="price_estimated_total" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Deposit -->
                    <ion-col size="12" size-lg="4">
                      <base-input label-text="Deposit (1 night) *">
                        <v-field name="price_deposit" v-slot="{ field }" v-model="reservation.price_deposit"
                          :rules="required">
                          <ion-input name="price_deposit" v-bind="field" placeholder="0.00" type="number"
                            :disabled="true"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="price_deposit" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>

                  <ion-row>
                    <!-- General Notes -->
                    <ion-col>
                      <base-input label-text="General Notes">
                        <v-field name="notes" v-slot="{ field }" v-model="reservation.general_notes">
                          <ion-textarea :auto-grow="true" rows="1" v-bind="field" placeholder="General Notes"
                            autocapitalize autocorrect>
                          </ion-textarea>
                        </v-field>
                      </base-input>
                      <v-error-message name="notes" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </base-card>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-button type="submit" expand="block" class="margin-x-8 margin-y-16"
          :disabled="disableUpdateReservation">Update Reservation</ion-button>
      </v-form>
    </section>

    <ion-modal :is-open="showUserSearchModal" @didDismiss="() => {
    showUserSearchModal = false;
  }
    ">
      <user-search-modal @select-user="selectUser"
        @cancel-modal="() => (this.showUserSearchModal = false)"></user-search-modal>
    </ion-modal>

    <!-- Check out Alert -->
    <ion-alert :is-open="showUpdateAlert" header="⚠️ Read this ⚠️"
      :message="`Any financial changes need to made manually by adding Line Items into the Folio`"
      :buttons="updateAlertButtons" @didDismiss="() => (this.showUpdateAlert = false)">
    </ion-alert>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonButton,
  IonModal,
  IonAlert,
} from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";
import UserSearchModal from "@/components/users/UserSearchModal.vue";
import { formatDate, formatCurrency } from "@/util/helpers";
import { format, addDays, differenceInHours, differenceInDays } from "date-fns";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonTextarea,
    IonButton,
    IonModal,
    IonAlert,
    UserSearchModal,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  computed: {
    disableUpdateReservation() {
      if (
        this.reservation.arrival_date &&
        this.reservation.departure_date &&
        this.reservation.property_id &&
        this.reservation.user.id &&
        this.reservation.room_type_id &&
        this.reservation.room_id &&
        this.reservation.price_retail &&
        this.reservation.price_final
      )
        return false;

      return true;
    },

    originalReservationDepositPaid() {
      let depositTransaction = this.originalReservation.folio.transactions.find(
        (x) => x.is_deposit
      );

      if (
        depositTransaction &&
        depositTransaction.stripe_payment_intent_status === "succeeded"
      )
        return true;

      return false;
    },
  },

  data() {
    return {
      originalReservation: {
        user: {},
        price_discount: 0,
        price_deposit: 0,
        price_final: 0,
        price_retail: 0,
        price_daily: 0,
        price_estimated_taxes_fees: 0,
        price_estimated_total: 0,
        room: {},
        room_type: {},
        folio: {
          transactions: [],
        },
      },
      reservation: {
        user: {},
        price_discount: 0,
      },
      properties: [],
      room_types: [],
      rooms: [],
      required: yup.string().required(),
      showUserSearchModal: false,
      showUpdateAlert: false,
      updateAlertButtons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            this.showUpdateAlert = false;
          },
        },
        {
          text: "I understand",
          handler: () => {
            this.updateReservation();
          },
        },
      ],
      available_rooms: [],
      showAvailableRooms: false,
      minDepartureDate: null,
      formatDate,
      formatCurrency,
    };
  },

  watch: {
    "reservation.room_type_id": function () {
      // Set Price Retail based on Room Type Rate
      if (this.reservation.arrival_date && this.reservation.departure_date) {
        if (this.reservation.room_type_id) {
          // Find the Room Type
          let room_type = this.room_types.find(
            (x) => x.id === this.reservation.room_type_id
          );

          if (room_type && room_type.price_retail) {
            // Set the Daily Rate
            this.reservation.price_daily = room_type.price_retail;

            // Set the Reservation Retail Total Price
            this.reservation.price_retail =
              Math.floor(differenceInHours(
                new Date(this.reservation.departure_date),
                new Date(this.reservation.arrival_date)) / 24) * room_type.price_retail;
          }
        }
      }
    },

    "reservation.price_retail": function () {
      this.reservation.price_final =
        this.reservation.price_retail *
        (1 - this.reservation.price_discount / 100);
    },

    "reservation.price_discount": function () {
      this.reservation.price_final =
        this.reservation.price_retail *
        (1 - this.reservation.price_discount / 100);
    },

    "reservation.price_final": function () {
      // Set the Estimated Taxes & fees
      // City, State, Occupncy, Javitz
      // 8.875% + 5.875% + $2.00 (x nights) + $1.50 (x nights)
      let duration = differenceInDays(new Date(this.reservation.departure_date), new Date(this.reservation.arrival_date))

      this.reservation.price_estimated_taxes_fees =
        this.reservation.price_final * 0.08875 +
        this.reservation.price_final * 0.05875 +
        (2 * duration) +
        (1.50 * duration);
      this.reservation.price_estimated_taxes_fees =
        this.reservation.price_estimated_taxes_fees.toFixed(2);

      // Set the Estimated Total
      this.reservation.price_estimated_total =
        Number(this.reservation.price_final) +
        Number(this.reservation.price_estimated_taxes_fees) +
        5.48; // Porterage Fee
      this.reservation.price_estimated_total =
        this.reservation.price_estimated_total.toFixed(2);
    },

    "reservation.arrival_date": function () {
      let minDepartureDate = addDays(
        new Date(this.reservation.arrival_date),
        2
      );

      this.minDepartureDate = format(minDepartureDate, "yyyy-MM-dd");

      // Update the departure date
      if (this.reservation.duration) {
        this.reservation.departure_date = format(
          addDays(
            new Date(this.reservation.arrival_date),
            this.reservation.duration + 1
          ),
          "yyyy-MM-dd"
        );
      }
    },

    "reservation.duration": function () {
      if (this.reservation.arrival_date) {
        this.reservation.departure_date = format(
          addDays(
            new Date(this.reservation.arrival_date),
            this.reservation.duration + 1
          ),
          "yyyy-MM-dd"
        );
      }
    },
  },

  async ionViewWillEnter() {
    this.setShowLoading(true);

    await this.fetchReservation();
    await this.fetchProperties();
    await this.fetchRooms();

    this.setShowLoading(false)
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchReservation() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/reservations/${this.$route.params.id}`)
        .then(async (response) => {
          this.originalReservation = { ...response.data.reservation };
          this.originalReservation.duration =
            Math.floor(differenceInHours(
              new Date(response.data.reservation.departure_date),
              new Date(response.data.reservation.arrival_date)) / 24)


          this.reservation = response.data.reservation;
          this.reservation.duration = Math.floor(differenceInHours(
            new Date(this.reservation.departure_date),
            new Date(this.reservation.arrival_date)) / 24)

          await this.fetchRoomTypes();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async fetchProperties() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/properties`)
        .then((response) => {
          this.properties = response.data.properties;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async fetchRoomTypes() {
      if (this.reservation.property_id) {
        await this.axios
          .get(
            `${process.env.VUE_APP_API}/room_types/?property_id=${this.reservation.property_id}`
          )
          .then((response) => {
            this.room_types = response.data.room_types;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async fetchRooms() {
      if (this.reservation.property_id && this.reservation.room_type_id) {
        await this.axios
          .get(
            `${process.env.VUE_APP_API}/rooms/?property_id=${this.reservation.property_id}&room_type_id=${this.reservation.room_type_id}`
          )
          .then((response) => {
            this.rooms = response.data.rooms;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async selectedPropertyUpdateRoomTypes() {
      // Set Room Type ID to null to force user to select a new Room Type
      this.reservation.room_type_id = null;
    },

    async selectedRoomTypeUpdateRooms() {
      // Load Rooms from Available Rooms
      this.rooms = this.available_rooms.filter(
        (x) => x.room_type_id === this.reservation.room_type_id
      );

      // Set Room ID to null to force user to select a new Room Type
      this.reservation.room_id = null;
    },

    selectUser(user) {
      this.reservation.user_id = user.id;
      this.reservation.user = user;
      this.showUserSearchModal = false;
    },

    /**
     * Fetch All Rooms
     * 
     * This is a temporary method that fetches all rooms so that manual bookings can be booked on top of one another
     * Normally we should use the SearchAvailablity method instead
     * 
     */
    async fetchAllRooms() {
      if (this.reservation.property_id) {
        this.showAvailableRooms = true;

        await this.axios
          .get(
            `${process.env.VUE_APP_API}/rooms/?property_id=${this.reservation.property_id}`
          )
          .then((response) => {
            this.available_rooms = response.data.rooms;

            // Sort Rooms based on Room Type
            this.available_rooms = this.available_rooms.sort(function (a, b) {
              if (a.room_type_id === b.room_type_id) {
                // Price is only important when cities are the same
                return b.number > a.number;
              }
              return a.room_type_id < b.room_type_id ? 1 : -1;
            });

            // Set Room Types as uniques from Rooms
            this.room_types = this.available_rooms
              .map((x) => x.room_type)
              .filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.id === value.id)
              );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async searchAvailability() {
      if (
        this.reservation.property_id &&
        this.reservation.arrival_date &&
        this.reservation.departure_date
      ) {
        this.showAvailableRooms = true;

        const config = {
          method: "post",
          url: `${process.env.VUE_APP_API}/reservations/availability`,
          data: this.reservation,
        };

        // Show a network loading indicator
        this.setShowLoading(true);

        await this.axios(config)
          .then((response) => {
            // Clear any existing data about the reservation
            this.reservation.room_type_id = null;
            this.reservation.room_id = null;
            this.reservation.price_daily = 0;
            this.reservation.price_discount = 0;
            this.reservation.price_final = 0;
            this.reservation.price_retail = 0;
            this.reservation.price_estimated_taxes_fees = 0;
            this.reservation.price_estimated_total = 0;

            // Turn off the network loading indicator and prompt user with a toast
            this.setShowLoading(false);

            // Sort Rooms based on Room Type
            this.available_rooms = response.data.available_rooms.sort(function (
              a,
              b
            ) {
              if (a.room_type_id === b.room_type_id) {
                // Price is only important when cities are the same
                return b.number > a.number;
              }
              return a.room_type_id < b.room_type_id ? 1 : -1;
            });

            // Set Room Types as uniques from Rooms
            this.room_types = this.available_rooms
              .map((x) => x.room_type)
              .filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.id === value.id)
              );
          })
          .catch((error) => {
            console.log(error);

            // Turn off the network loading indicator and prompt user with a toast
            this.setShowLoading(false);
            this.loadToast({
              message: "Something went wrong",
              color: "danger",
            });
          });
      }
    },

    async onSubmit() {
      if (this.showAvailableRooms) {
        this.showUpdateAlert = true;
      } else {
        this.updateReservation();
      }
    },

    async updateReservation() {
      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/reservations/${this.reservation.id}`,
        data: this.reservation,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then(() => {
          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);

          this.$router.back();
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>

<style scoped>
table,
thead {
  border: 1px solid #6a6a6a;
}

td {
  padding-left: 10px;
}
</style>